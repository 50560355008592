<template>
  <svg viewBox="0 0 90 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.2066 18.7451C29.6161 18.2834 29.9622 17.965 30.7705 17.9399C31.8963 17.9049 31.8452 18.2817 32.447 18.6305C32.6588 15.9619 32.1696 9.06171 32.6799 7.28111C33.4376 4.63808 36.4397 2.33835 39.6974 3.93324C41.001 4.57145 45.134 7.216 46.7585 8.1742C47.2691 8.47519 53.489 12.1803 53.8245 12.4907C54.9126 12.4002 55.8355 12.6406 55.895 13.723C56.465 13.9969 57.1197 14.4324 57.697 14.7851C58.3169 15.1638 58.9234 15.5173 59.5387 15.895C60.1939 16.2971 62.8678 17.8048 63.1953 18.1745C64.7712 17.8195 65.6406 17.574 67.3655 17.9508C68.0071 18.0909 68.5605 18.3351 69.0309 18.6386C69.5579 18.9784 69.8694 19.4344 70.2392 19.817C70.0602 18.9723 68.5361 17.5142 68.0021 17.1058C67.076 16.3974 60.1017 12.2591 58.7875 11.4699C54.6845 9.00572 50.4744 6.4506 46.4349 4.00063C45.0458 3.15796 41.462 0.842776 40.149 0.415615C34.879 -1.29861 29.4628 2.46427 29.1914 8.49596L29.2066 18.7451Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M62.2765 30.4498L57.7307 33.1686C53.305 35.9206 48.5384 38.6665 44.1137 41.3992C43.3507 41.8707 42.6295 42.3088 41.8531 42.7709C41.1459 43.192 40.291 43.781 39.5664 44.1117C37.5808 45.0179 35.5045 44.4246 34.1753 43.1555C32.5829 41.6353 32.4656 40.4017 32.4656 37.5094C32.4656 35.9796 32.5355 31.0741 32.4439 29.9538C31.9181 30.2669 31.8197 30.6888 30.7744 30.6652C29.8297 30.6439 29.7124 30.2651 29.2156 29.9196C29.1278 30.6865 29.1455 38.5223 29.1987 39.5973C29.4795 45.2695 34.5231 48.9397 39.3582 47.7889C41.2185 47.3463 43.7442 45.629 45.4195 44.5953C49.254 42.2292 53.1677 39.9009 57.0378 37.5654C58.9743 36.3969 60.9833 35.2522 62.8744 34.0407L67.2381 31.4012C67.6693 31.1276 68.2614 30.5988 68.5724 30.444C67.8962 29.839 68.0428 29.8679 67.997 28.7518C67.769 28.915 67.686 29.0822 67.4766 29.3054C67.2785 29.5165 67.1607 29.6011 66.9377 29.784C65.1712 31.234 64.0836 31.0842 62.2765 30.4498Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.5463 25.7833C18.0488 28.6364 14.3787 28.3909 14.0914 25.6977C14.036 25.1801 14.0314 23.2432 14.1166 22.7595C14.3557 21.4025 16.0318 20.1327 17.4858 21.1981C17.9235 21.5188 18.466 22.1765 18.5536 22.9384C18.6005 23.3466 18.6089 25.4248 18.5463 25.7833ZM21.8427 26.0438C22.0449 24.3124 21.9721 21.9523 21.191 20.6192C18.3376 15.7489 11.3507 17.6224 10.801 22.5837C10.594 24.4525 10.6818 26.681 11.5062 28.0712C14.4401 33.0195 21.2754 30.9042 21.8427 26.0438Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.4474 18.6308C31.8456 18.2819 31.8967 17.9052 30.7708 17.9402C29.9626 17.9652 29.6165 18.2837 29.207 18.7453L29.2148 29.9202C29.7115 30.2658 29.8288 30.6445 30.7735 30.6658C31.8188 30.6894 31.9172 30.2675 32.443 29.9544C32.8365 27.002 31.9325 22.7347 33.1197 21.4664C33.5521 21.0045 34.4876 20.6197 35.3547 20.9822C37.2036 21.7552 36.8205 23.8495 36.8205 25.6106C36.8205 26.624 36.8217 27.6372 36.8205 28.6506C36.8193 29.8475 36.8108 30.3793 37.9347 30.6131C38.7449 30.7819 39.8468 30.5156 40.0703 29.8804C40.1818 29.5632 40.1278 27.3522 40.1278 26.8267C40.1278 25.0757 40.3136 22.5817 39.7655 21.0557C39.2727 19.6835 38.178 18.3792 36.8488 17.949C35.4422 17.4937 33.906 17.8748 32.8999 18.9766L32.5492 19.3263C32.5388 19.3367 32.5182 19.3587 32.5018 19.3739L32.4474 18.6308Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M67.7655 25.1964C68.093 27.1293 65.5932 29.0606 64.4403 27.7868C63.7755 27.0525 64.0809 25.9035 64.8892 25.5499C65.6648 25.2101 66.8753 25.2106 67.7655 25.1964ZM63.196 18.1744C62.3616 18.6357 61.2728 18.809 61.5584 20.2091C62.1432 23.0733 63.9595 19.6094 66.564 20.696C67.5793 21.1199 67.7851 22.3821 67.8096 23.4118C65.5821 23.355 62.8455 23.4546 61.5775 24.8655C60.9066 25.6119 60.6662 26.7044 60.8394 28.0077C61.0157 29.336 61.5424 29.8473 62.276 30.4501C64.0831 31.0845 65.1707 31.2342 66.9372 29.7842C67.1602 29.6013 67.278 29.5167 67.476 29.3056C67.6855 29.0824 67.7684 28.9152 67.9965 28.7521C68.0422 29.8681 67.8956 29.8392 68.5719 30.4442C69.4728 30.8248 70.8748 30.6804 71.076 29.7678C71.2085 29.1673 71.0973 26.9167 71.1084 26.1313C71.1457 23.5202 71.4008 21.8759 70.2399 19.8169C69.8701 19.4343 69.5586 18.9783 69.0316 18.6385C68.5612 18.335 68.0079 18.0907 67.3662 17.9506C65.6413 17.5739 64.7719 17.8194 63.196 18.1744Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M55.8946 13.7234C55.8351 12.641 54.9122 12.4006 53.8241 12.491C53.1012 12.6714 52.6018 12.9033 52.5749 13.7056C52.506 15.7538 52.5774 19.2007 52.5774 21.3548C52.5774 23.0459 52.535 24.7974 52.5812 26.4812C52.6838 30.2091 55.3732 30.8755 58.3944 30.6626C60.0281 30.5476 60.1123 28.2993 58.9325 27.7338C57.4824 27.0389 55.8752 28.7161 55.883 25.7845C55.8873 24.1602 55.7954 22.2577 55.8989 20.6697C58.5361 20.6699 59.7149 21.0705 59.749 19.3583C59.7857 17.5106 58.6176 17.9398 55.9285 17.9301L55.8946 13.7234Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M86.1557 20.665C87.0174 20.6799 87.8842 20.668 88.7467 20.6723C89.6626 20.6769 90.007 20.2776 89.9995 19.2697C89.9922 18.2908 89.6309 17.928 88.7491 17.9325C87.8787 17.9368 86.9974 17.9181 86.1289 17.9424C86.1506 16.9138 86.1352 15.8704 86.1352 14.84C86.1352 13.6601 86.3611 13.0442 85.3818 12.6315C84.6178 12.3092 83.5087 12.4934 83.0552 12.9632C82.667 13.3655 82.8305 15.0658 82.8305 15.7954C82.8305 17.784 82.6881 26.4674 82.9732 27.7428C83.3309 29.3415 84.4891 30.2617 86.1069 30.5465C87.7193 30.83 90.3502 31.0522 89.9395 28.7788C89.5387 26.5599 86.4641 28.6022 86.1656 26.6503C86.0341 25.7897 86.0696 21.6232 86.1557 20.665Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45.6589 17.818C44.1606 17.9954 43.3446 18.5441 42.7537 19.3947C41.4695 21.2437 41.5392 24.0663 44.5696 25.0582C45.5142 25.3671 47.2933 25.7456 47.8106 26.3544C48.9043 27.642 46.7291 29.037 43.8249 27.5779C43.363 27.3458 42.7786 26.8064 42.2004 27.2417C41.5612 27.7225 41.0155 29.1191 42.6287 30.0433C43.7187 30.6676 44.8832 30.9237 46.2843 30.9065C49.0083 30.8725 51.0877 29.5313 50.8896 26.5736C50.7941 25.1444 50.0988 24.3182 49.149 23.8249C47.9569 23.2057 47.0086 23.1013 45.7938 22.6483C43.7635 21.8913 45.1609 19.7253 48.2145 20.8041C48.7236 20.984 49.4591 21.3876 49.937 21.0126C50.3394 20.6967 51.4706 19.2239 49.5691 18.347C48.4622 17.8365 46.9598 17.664 45.6589 17.818Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.64455 20.2244C9.88059 19.0884 8.75356 18.4709 7.94989 18.1565C6.95467 17.7671 5.71228 17.6934 4.58622 17.8241C2.32055 18.0871 0.973921 19.2923 0.350669 21.1958C-0.198818 22.8746 -0.0977232 26.6663 0.620093 28.1801C1.44843 29.9275 3.0033 30.8665 5.47768 30.9063C7.3293 30.9359 10.8453 29.9759 9.54998 27.7722C8.83241 26.5515 8.02898 27.3093 7.01852 27.6587C6.0308 28.0002 4.72843 27.8837 4.04495 27.286C3.24491 26.5868 3.3593 25.3552 3.35809 24.0461C3.35688 22.6899 3.3697 21.6987 4.37653 21.1517C6.45404 20.0228 8.04131 22.0091 8.91464 21.3896C9.18624 21.1971 9.55047 20.6772 9.64455 20.2244Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M76.427 20.0179C76.325 18.9411 76.5687 18.2846 75.5247 18.0082C74.6961 17.7888 73.676 18.0267 73.3947 18.6497C73.2232 19.029 73.3064 26.0493 73.3064 27.4341C73.3064 28.2049 73.1325 29.6437 73.5608 30.1327C74.0155 30.6523 74.9713 30.8329 75.7602 30.5107C76.6485 30.1476 76.6152 29.6667 76.614 28.5631C76.6128 27.066 76.4398 24.2023 76.9151 22.954C77.8709 20.4443 79.7273 20.9527 80.5194 20.8088C81.6711 20.5998 82.4145 17.7556 79.7878 17.7706C78.2143 17.7794 77.1456 18.6794 76.427 20.0179Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.6423 18.0285C23.4301 18.401 23.7315 19.2117 23.7315 21.0942L23.7295 29.0871C23.7254 29.8561 23.8243 30.1368 24.3455 30.4324C24.7954 30.6878 25.4537 30.7441 25.9672 30.5627C27.233 30.115 26.9104 28.9845 26.9104 27.5216L26.9126 19.5302C26.9169 18.7529 26.8465 18.4157 26.2982 18.1398C25.837 17.9079 25.1577 17.8704 24.6423 18.0285Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.6707 12.1369C22.3511 12.8083 23.5872 15.6165 25.8127 15.034C27.9371 14.4782 27.0853 11.4379 24.6707 12.1369Z"
      fill="white"
    />
  </svg>
</template>
