<template>
  <div :class="$style.HeaderUser">
    <VButton size="small" @click="onButtonClick">{{ btnText }}</VButton>
    <HeaderUserMenu v-if="isLogged" :class="$style.menu" />
  </div>
</template>

<script setup lang="ts">
import LoginModal from "~/components/layout/modals/LoginModal.vue";

interface HeaderUserProps {
  isLogged: boolean;
}

const { t } = useI18n({
  useScope: "local",
});

const props = withDefaults(defineProps<HeaderUserProps>(), {});

const btnText = computed(() => {
  if (props.isLogged) {
    return t("Кабинет");
  }
  return t("Войти");
});

const { $modal } = useNuxtApp();

function onButtonClick() {
  if (!props.isLogged) {
    $modal.open(markRaw(LoginModal), {});
  }
}
</script>

<i18n lang="json">
{
  "ru": {
    "Войти": "Войти",
    "Кабинет": "Кабинет"
  },
  "en": {
    "Войти": "Login",
    "Кабинет": "Account"
  }
}
</i18n>

<style lang="scss" module>
.HeaderUser {
  position: relative;

  &:hover {
    .menu {
      opacity: 1;
      visibility: visible;
    }
  }
}

.menu {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  bottom: -10px;
  right: 0;
  transform: translateY(100%);
  transition: all $base-transition;

  &:before {
    content: "";
    position: absolute;
    top: -10px;
    width: 100%;
    height: 10px;
  }
}
</style>
