import { defineStore } from "pinia";
import {
  SvgoIconRules,
  SvgoIconPartners,
  SvgoIconAml,
  SvgoIconFaq,
  SvgoIconContacts,
  SvgoIconHome,
} from "#components";

export const useHeaderStore = defineStore("header", () => {
  const isBurgerMenuVisible = ref(false);

  const { t } = useI18n();

  const menuItems = computed(() => [
    {
      icon: markRaw(SvgoIconHome),
      title: t("Главная"),
      link: "/",
      hideOnDesktop: true,
    },
    {
      icon: markRaw(SvgoIconRules),
      title: t("Правила обмена"),
      link: "/rules",
      hideOnDesktop: false,
    },
    {
      icon: markRaw(SvgoIconPartners),
      title: t("Партнерам"),
      link: "/partners",
      hideOnDesktop: false,
    },
    {
      icon: markRaw(SvgoIconAml),
      title: "AML / KYC",
      link: "/aml-kyc",
      hideOnDesktop: false,
    },
    {
      icon: markRaw(SvgoIconFaq),
      title: "FAQ",
      link: "/faq",
      hideOnDesktop: false,
    },
    {
      icon: markRaw(SvgoIconContacts),
      title: t("Контакты"),
      link: "/contacts",
      hideOnDesktop: false,
    },
  ]);

  function toggleBurgerMenu(value?: boolean) {
    if (value !== undefined) {
      isBurgerMenuVisible.value = value;
    } else {
      isBurgerMenuVisible.value = !isBurgerMenuVisible.value;
    }
  }

  return {
    menuItems,
    toggleBurgerMenu,
    isBurgerMenuVisible,
  };
});
