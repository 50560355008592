<template>
  <div :class="$style.DefaultLayout">
    <TheHeader />
    <main :class="$style.page">
      <slot />
    </main>
    <TheFooter :is-home="isIndex" />
    <TheBurgerMenu />
  </div>
</template>

<script setup lang="ts">
import MessageModal from "~/components/layout/modals/MessageModal.vue";

const route = useRoute();

const isIndex = computed(() => route?.name?.includes("index"));

const { t } = useI18n({
  useScope: "local",
});

function showMovedModal() {
  const hasSeenMovedModal = localStorage.getItem("hasSeenMovedNotification");
  if (hasSeenMovedModal) {
    return;
  }
  const { $modal } = useNuxtApp();
  localStorage.setItem("hasSeenMovedNotification", "true");
  setTimeout(() => {
    $modal.open(markRaw(MessageModal), {
      title: t("Новый Coinstart"),
      message: t("Сообщение"),
      buttonText: t("Хорошо"),
    });
  }, 1500);
}

onMounted(() => {
  showMovedModal();
});
</script>

<i18n lang="json">
{
  "ru": {
    "Новый Coinstart": "Новый Coinstart",
    "Сообщение": "Рады сообщить, что мы обновили дизайн сайта. Мы постарались сделать обмен проще и понятнее. Надеемся, что у Вас не возникнет трудностей и взаимодействие с сайтом станет намного интуитивнее для Вас. В любом случае, наша поддержка всегда готова помочь Вам с любыми возникающими в процессе обмена вопросами. Спасибо, что доверяете нам!",
    "Хорошо": "Ок"
  },
  "en": {
    "Новый Coinstart": "New Coinstart",
    "Сообщение": "We are pleased to announce that we have updated the website design. We have made efforts to make the exchange process simpler and more understandable. We hope that you will not encounter any difficulties and that interacting with the website will become much more intuitive for you. In any case, our support team is always ready to assist you with any questions that may arise during the exchange process. Thank you for trusting us!",
    "Хорошо": "Ok"
  }
}
</i18n>

<style lang="scss" module>
.DefaultLayout {
  width: 100%;
  height: 100%;
}

.page {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - #{$footer-h});
  padding-top: 8rem;

  @include respond-to(xs) {
    padding-top: 40px;
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  top: 0;
}
</style>
