<template>
  <div :class="[$style.HeaderUserMenuEmail, { [$style.burger]: isBurger }]">
    <div :class="$style.box">
      <SvgoIconUser :class="$style.icon" />
    </div>
    <div :class="$style.email">{{ userStore.user?.email }}</div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "~/stores/useUserStore";

const userStore = useUserStore();

defineProps({
  isBurger: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" module>
.HeaderUserMenuEmail {
  display: flex;
  align-items: center;
  padding: 2rem;
}

.burger {
  padding: 15px;
}

.box {
  width: 3rem;
  height: 3rem;
  background-color: $base-200;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  color: $brand-blue;
}

.burger .icon {
  width: 24px;
  height: 24px;
}

.email {
  margin-left: 1rem;
  color: $brand-blue;
  line-height: 1;
  font-weight: 450;
  overflow: hidden;
  text-overflow: ellipsis;
}

.burger .email {
  font-size: 16px;
}
</style>
