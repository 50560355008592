<template>
  <transition name="fade-fast" @enter="onEnter" @after-leave="afterLeave">
    <div v-if="headerStore.isBurgerMenuVisible" :class="[$style.TheBurgerMenu]">
      <div
        ref="wrapper"
        :class="[$style.wrapper, { [$style._opened]: isContentVisible }]"
      >
        <div :class="$style.content">
          <template v-if="userStore.isLogged">
            <HeaderUserMenuEmail is-burger></HeaderUserMenuEmail>
            <HeaderUserMenuNav is-burger></HeaderUserMenuNav>
          </template>
          <div v-else :class="$style.header">
            <VButton size="small" @click="openLoginModal">{{
              t("Войти")
            }}</VButton>
          </div>
          <HeaderMenu :items="headerStore.menuItems" is-burger />
          <HeaderUserMenuFooter v-if="userStore.isLogged" is-burger />
        </div>
        <div :class="$style.footer">
          <HeaderLangSwitch :class="$style.language" />
          <HeaderThemeSwitcher />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { useUserStore } from "~/stores/useUserStore";
import { useHeaderStore } from "~/stores/useHeaderStore";
import HeaderMenu from "~/components/layout/header/HeaderMenu.vue";
import VButton from "~/components/ui/button/VButton.vue";
import LoginModal from "~/components/layout/modals/LoginModal.vue";

const { t } = useI18n({
  useScope: "local",
});

const { $modal } = useNuxtApp();

function openLoginModal() {
  $modal.open(markRaw(LoginModal), {});
}

const isContentVisible = ref(false);

const headerStore = useHeaderStore();

const userStore = useUserStore();

const wrapper = ref<HTMLElement | null>(null);

onClickOutside(wrapper, () => {
  isContentVisible.value = false;
  setTimeout(() => {
    headerStore.toggleBurgerMenu(false);
  }, 200);
});

function onEnter() {
  isContentVisible.value = true;
  lockBody();
}

function afterLeave() {
  isContentVisible.value = false;
  setTimeout(() => {
    headerStore.toggleBurgerMenu(false);
  }, 200);
  unlockBody();
}

const route = useRoute();

watch(
  () => route.name,
  () => {
    afterLeave();
  },
);
</script>

<style lang="scss" module>
.TheBurgerMenu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgb(2 14 29 / 80%);
  backdrop-filter: blur(10px);
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 100%;
  background-color: var(--main-block-bg);
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.4s;
}

.content {
  @include respond-to(xs) {
    flex: 1 0 auto;
  }
}

.header {
  padding: 15px;
  border-bottom: 1px solid rgba($base-950, 0.1);
}

._opened {
  transform: translateX(0);
}

.language {
  justify-content: flex-start;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}
</style>
