<template>
  <div :class="[$style.HeaderUserMenuFooter, { [$style.burger]: isBurger }]">
    <div :class="$style.item">
      <NuxtLinkLocale to="/account/settings" :class="$style.link">
        <SvgoIconKey :class="$style.icon" />
        <span>{{ $t("Изменить пароль") }}</span>
      </NuxtLinkLocale>
    </div>
    <div :class="$style.item">
      <button type="button" :class="$style.link" @click="logout">
        <SvgoIconLogout :class="$style.icon" />
        <span>{{ $t("Выйти") }}</span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "~/stores/useUserStore";

defineProps({
  isBurger: {
    type: Boolean,
    default: false,
  },
});
const localePath = useLocalePath();
function logout() {
  useUserStore().logout();
  useRouter().push(localePath("/"));
}
</script>

<style lang="scss" module>
.HeaderUserMenuFooter {
  display: flex;
  flex-direction: column;
}

.item {
  padding: 0.5rem 0;

  &:first-child {
    border-bottom: 1px solid rgba($base-950, 0.1);
  }
}

.link {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 2rem;
  transition: background-color $base-transition;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: rgba($base-950, 0.1);
  }

  &:global(.router-link-active) {
    color: $brand-blue;

    .icon {
      color: $brand-blue;
    }
  }
}

.burger {
  border-top: 1px solid rgba($base-950, 0.1);
}

.burger .link {
  padding: 5px 15px;
  font-size: 16px;
}

.icon {
  width: 3rem;
  height: 3rem;
  color: var(--text-color-4);
}

.burger .icon {
  width: 24px;
  height: 24px;
}
</style>
