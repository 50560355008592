<template>
  <svg viewBox="0 0 90 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.2066 18.7451C29.6161 18.2834 29.9622 17.965 30.7705 17.9399C31.8963 17.9049 31.8452 18.2817 32.447 18.6305C32.6588 15.9619 32.1696 9.06171 32.6799 7.28111C33.4376 4.63808 36.4397 2.33835 39.6974 3.93324C41.001 4.57145 45.134 7.216 46.7585 8.1742C47.2691 8.47519 53.489 12.1803 53.8245 12.4907C54.9126 12.4002 55.8355 12.6406 55.895 13.723C56.465 13.9969 57.1197 14.4324 57.697 14.7851C58.3169 15.1638 58.9234 15.5173 59.5387 15.895C60.1939 16.2971 62.8678 17.8048 63.1953 18.1745C64.7712 17.8195 65.6406 17.574 67.3655 17.9508C68.0071 18.0909 68.5605 18.3351 69.0309 18.6386C69.5579 18.9784 69.8694 19.4344 70.2392 19.817C70.0602 18.9723 68.5361 17.5142 68.0021 17.1058C67.076 16.3974 60.1017 12.2591 58.7875 11.4699C54.6845 9.00572 50.4744 6.4506 46.4349 4.00063C45.0458 3.15796 41.462 0.842776 40.149 0.415615C34.879 -1.29861 29.4628 2.46427 29.1914 8.49596L29.2066 18.7451Z"
      fill="#1EAEFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M62.2765 30.45L57.7307 33.1687C53.305 35.9207 48.5384 38.6666 44.1137 41.3993C43.3507 41.8708 42.6295 42.3089 41.8531 42.771C41.1459 43.1921 40.291 43.7812 39.5664 44.1118C37.5808 45.018 35.5045 44.4247 34.1753 43.1556C32.5829 41.6355 32.4656 40.4019 32.4656 37.5095C32.4656 35.9798 32.5355 31.0742 32.4439 29.9539C31.9181 30.267 31.8197 30.6889 30.7744 30.6653C29.8297 30.644 29.7124 30.2653 29.2156 29.9197C29.1278 30.6866 29.1455 38.5225 29.1987 39.5975C29.4795 45.2696 34.5231 48.9398 39.3582 47.789C41.2185 47.3464 43.7442 45.6292 45.4195 44.5954C49.254 42.2293 53.1677 39.901 57.0378 37.5655C58.9743 36.397 60.9833 35.2524 62.8744 34.0408L67.2381 31.4013C67.6693 31.1277 68.2614 30.5989 68.5724 30.4441C67.8962 29.8391 68.0428 29.868 67.997 28.752C67.769 28.9151 67.686 29.0823 67.4766 29.3055C67.2785 29.5166 67.1607 29.6012 66.9377 29.7841C65.1712 31.2341 64.0836 31.0844 62.2765 30.45Z"
      fill="#1EAEFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.5463 25.7838C18.0488 28.6369 14.3787 28.3914 14.0914 25.6982C14.036 25.1806 14.0314 23.2437 14.1166 22.76C14.3557 21.403 16.0318 20.1332 17.4858 21.1986C17.9235 21.5193 18.466 22.177 18.5536 22.9389C18.6005 23.347 18.6089 25.4253 18.5463 25.7838ZM21.8427 26.0443C22.0449 24.3128 21.9721 21.9528 21.191 20.6196C18.3376 15.7493 11.3507 17.6229 10.801 22.5842C10.594 24.453 10.6818 26.6815 11.5062 28.0717C14.4401 33.02 21.2754 30.9047 21.8427 26.0443Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.4474 18.6311C31.8456 18.2822 31.8967 17.9054 30.7708 17.9404C29.9626 17.9655 29.6165 18.284 29.207 18.7456L29.2148 29.9204C29.7115 30.266 29.8288 30.6448 30.7735 30.6661C31.8188 30.6896 31.9172 30.2678 32.443 29.9546C32.8365 27.0023 31.9325 22.7349 33.1197 21.4666C33.5521 21.0048 34.4876 20.6199 35.3547 20.9825C37.2036 21.7555 36.8205 23.8497 36.8205 25.6108C36.8205 26.6242 36.8217 27.6374 36.8205 28.6509C36.8193 29.8477 36.8108 30.3795 37.9347 30.6134C38.7449 30.7821 39.8468 30.5158 40.0703 29.8807C40.1818 29.5635 40.1278 27.3524 40.1278 26.8269C40.1278 25.076 40.3136 22.5819 39.7655 21.0559C39.2727 19.6838 38.178 18.3795 36.8488 17.9493C35.4422 17.494 33.906 17.875 32.8999 18.9769L32.5492 19.3265C32.5388 19.3369 32.5182 19.359 32.5018 19.3742L32.4474 18.6311Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M67.7655 25.1967C68.093 27.1295 65.5932 29.0609 64.4403 27.787C63.7755 27.0528 64.0809 25.9038 64.8892 25.5501C65.6648 25.2103 66.8753 25.2109 67.7655 25.1967ZM63.196 18.1746C62.3616 18.636 61.2728 18.8093 61.5584 20.2093C62.1432 23.0735 63.9595 19.6096 66.564 20.6963C67.5793 21.1201 67.7851 22.3824 67.8096 23.412C65.5821 23.3553 62.8455 23.4548 61.5775 24.8658C60.9066 25.6122 60.6662 26.7047 60.8394 28.0079C61.0157 29.3363 61.5424 29.8476 62.276 30.4503C64.0831 31.0847 65.1707 31.2344 66.9372 29.7845C67.1602 29.6016 67.278 29.5169 67.476 29.3059C67.6855 29.0827 67.7684 28.9155 67.9965 28.7523C68.0422 29.8683 67.8956 29.8395 68.5719 30.4445C69.4728 30.825 70.8748 30.6806 71.076 29.768C71.2085 29.1676 71.0973 26.917 71.1084 26.1316C71.1457 23.5205 71.4008 21.8762 70.2399 19.8171C69.8701 19.4345 69.5586 18.9785 69.0316 18.6388C68.5612 18.3352 68.0079 18.091 67.3662 17.9509C65.6413 17.5741 64.7719 17.8196 63.196 18.1746Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M55.8946 13.7234C55.8351 12.641 54.9122 12.4006 53.8241 12.491C53.1012 12.6714 52.6018 12.9033 52.5749 13.7056C52.506 15.7538 52.5774 19.2007 52.5774 21.3548C52.5774 23.0459 52.535 24.7974 52.5812 26.4812C52.6838 30.2091 55.3732 30.8755 58.3944 30.6626C60.0281 30.5476 60.1123 28.2993 58.9325 27.7338C57.4824 27.0389 55.8752 28.7161 55.883 25.7845C55.8873 24.1602 55.7954 22.2577 55.8989 20.6697C58.5361 20.6699 59.7149 21.0705 59.749 19.3583C59.7857 17.5106 58.6176 17.9398 55.9285 17.9301L55.8946 13.7234Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M86.1557 20.665C87.0174 20.6799 87.8842 20.668 88.7467 20.6723C89.6626 20.6769 90.007 20.2776 89.9995 19.2697C89.9922 18.2908 89.6309 17.928 88.7491 17.9325C87.8787 17.9368 86.9974 17.9181 86.1289 17.9424C86.1506 16.9138 86.1352 15.8704 86.1352 14.84C86.1352 13.6601 86.3611 13.0442 85.3818 12.6315C84.6178 12.3092 83.5087 12.4934 83.0552 12.9632C82.667 13.3655 82.8305 15.0658 82.8305 15.7954C82.8305 17.784 82.6881 26.4674 82.9732 27.7428C83.3309 29.3415 84.4891 30.2617 86.1069 30.5465C87.7193 30.83 90.3502 31.0522 89.9395 28.7788C89.5387 26.5599 86.4641 28.6022 86.1656 26.6503C86.0341 25.7897 86.0696 21.6232 86.1557 20.665Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45.6589 17.8183C44.1606 17.9956 43.3446 18.5444 42.7537 19.3949C41.4695 21.2439 41.5392 24.0666 44.5696 25.0585C45.5142 25.3673 47.2933 25.7458 47.8106 26.3547C48.9043 27.6422 46.7291 29.0372 43.8249 27.5781C43.363 27.346 42.7786 26.8066 42.2004 27.2419C41.5612 27.7228 41.0155 29.1193 42.6287 30.0435C43.7187 30.6678 44.8832 30.924 46.2843 30.9067C49.0083 30.8728 51.0877 29.5315 50.8896 26.5738C50.7941 25.1446 50.0988 24.3184 49.149 23.8251C47.9569 23.2059 47.0086 23.1015 45.7938 22.6485C43.7635 21.8915 45.1609 19.7255 48.2145 20.8043C48.7236 20.9842 49.4591 21.3878 49.937 21.0129C50.3394 20.6969 51.4706 19.2241 49.5691 18.3473C48.4622 17.8368 46.9598 17.6642 45.6589 17.8183Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.64455 20.2247C9.88059 19.0886 8.75356 18.4712 7.94989 18.1568C6.95467 17.7674 5.71228 17.6936 4.58622 17.8244C2.32055 18.0873 0.973921 19.2926 0.350669 21.196C-0.198818 22.8748 -0.0977232 26.6666 0.620093 28.1804C1.44843 29.9278 3.0033 30.8667 5.47768 30.9065C7.3293 30.9361 10.8453 29.9762 9.54998 27.7725C8.83241 26.5518 8.02898 27.3096 7.01852 27.659C6.0308 28.0005 4.72843 27.8839 4.04495 27.2863C3.24491 26.587 3.3593 25.3554 3.35809 24.0463C3.35688 22.6901 3.3697 21.699 4.37653 21.152C6.45404 20.023 8.04131 22.0093 8.91464 21.3899C9.18624 21.1973 9.55047 20.6774 9.64455 20.2247Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M76.427 20.0183C76.325 18.9416 76.5687 18.2851 75.5247 18.0087C74.6961 17.7893 73.676 18.0272 73.3947 18.6502C73.2232 19.0295 73.3064 26.0498 73.3064 27.4346C73.3064 28.2054 73.1325 29.6442 73.5608 30.1332C74.0155 30.6528 74.9713 30.8334 75.7602 30.5112C76.6485 30.1481 76.6152 29.6672 76.614 28.5636C76.6128 27.0665 76.4398 24.2028 76.9151 22.9545C77.8709 20.4447 79.7273 20.9532 80.5194 20.8093C81.6711 20.6003 82.4145 17.7561 79.7878 17.7711C78.2143 17.7799 77.1456 18.6798 76.427 20.0183Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.6423 18.0281C23.4301 18.4005 23.7315 19.2112 23.7315 21.0937L23.7295 29.0866C23.7254 29.8556 23.8243 30.1363 24.3455 30.432C24.7954 30.6873 25.4537 30.7436 25.9672 30.5622C27.233 30.1145 26.9104 28.984 26.9104 27.5211L26.9126 19.5297C26.9169 18.7524 26.8465 18.4152 26.2982 18.1393C25.837 17.9075 25.1577 17.87 24.6423 18.0281Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.6707 12.137C22.3511 12.8084 23.5872 15.6166 25.8127 15.0341C27.9371 14.4783 27.0853 11.438 24.6707 12.137Z"
      fill="currentColor"
    />
  </svg>
</template>
