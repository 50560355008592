<template>
  <footer :class="[$style.TheFooter, { [$style._home]: isHome }]">
    <div class="container">
      <div :class="$style.top">
        <NuxtLinkLocale to="/" :class="$style.logo">
          <LogoWhite />
        </NuxtLinkLocale>
        <span :class="[$style.copyright, $style._top]">
          © 2018-{{ currentYear }} Coinstart
        </span>
        <div :class="$style.right">
          <a :class="$style.mail" href="mailto:coinstart.cc@gmail.com">
            coinstart.cc@gmail.com
          </a>
          <SocialIcons color="white" />
        </div>
      </div>
      <div :class="$style.bottom">
        <div :class="$style.inner">
          <div :class="$style.partners">
            <div v-for="partner in partners" :key="partner.name">
              <a :href="partner.link" target="_blank" :class="$style.partner">
                <img :src="partner.image" :alt="partner.name" />
              </a>
            </div>
          </div>
          <p :class="$style.txt">
            {{ $t("Сайт предназначен только для лиц старше 18 лет") }}
          </p>
        </div>

        <div :class="$style.design">
          Дизайн — <a href="https://abraweb.ru/" target="_blank">Abramov</a>
        </div>
        <span :class="$style.copyright">
          © 2018-{{ currentYear }} Coinstart
        </span>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const partners = [
  {
    name: "bestchange",
    link: "https://www.bestchange.ru/coinstart-exchanger.html",
    image: "/images/monitorings/bestchange.png",
  },
  {
    name: "wellcrypto",
    link: "https://wellcrypto.io/ru/exchangers/coinstart/",
    image: "/images/monitorings/wellcrypto.png",
  },
  {
    name: "exnode",
    link: "https://exnode.ru/exchangers/exchanger-9199921",
    image: "/images/monitorings/exnode.png",
  },
  {
    name: "kurs.expert",
    link: "https://kurs.expert/ru/obmennik/coinstart-cc/feedbacks.html#reputation",
    image: "/images/monitorings/kurs-expert.png",
  },
  {
    name: "exchange.sumo",
    link: "https://exchangesumo.com/exchanger/1026/Coinstart/",
    image: "/images/monitorings/exchange-sumo.png",
  },
];

const currentYear = new Date().getFullYear();
defineProps({
  isHome: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" module>
.TheFooter {
  height: $footer-h;
  background: var(--footer-bg);
  border-radius: 2rem 2rem 0 0;
  padding-top: 3rem;
  padding-bottom: 1.5rem;

  @include respond-to(xs) {
    height: auto;
    padding-top: 60px;
    border-radius: 15px 15px 0 0;
    padding-bottom: 60px;
  }
}

._home {
  background: var(--footer-bg-home);
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3rem;
  border-bottom: 1px solid rgba($base-0, 0.4);

  @include respond-to(xs) {
    flex-direction: column;
    padding-bottom: 8rem;
    border-bottom: none;
  }
}

.logo {
  display: inline-flex;

  svg {
    width: 9rem;
    height: 4.8rem;
    color: var(--footer-logo);
  }

  @include respond-to(xs) {
    margin-bottom: 2rem;
  }
}

.right {
  display: flex;
  align-items: center;
  gap: 1.2rem;

  @include respond-to(xs) {
    flex-direction: column;
    gap: 20px;
  }
}

.mail {
  color: $base-0;

  &:hover {
    color: $base-100;
  }
}

.bottom {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 1.4rem;
  justify-content: space-between;

  @include respond-to(xs) {
    flex-direction: column;
    justify-content: center;
    padding-top: 0;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  @include respond-to(xs) {
    align-items: center;
  }
}

.txt {
  color: rgba($base-0, 0.6);
  font-size: 14px;
}

.design {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 400;
  color: rgba($base-0, 0.6);

  a {
    text-decoration-line: underline;
  }

  @include respond-to(xs) {
    position: relative;
    left: unset;
    transform: unset;
    margin-top: 80px;
    font-size: 12px;
  }
}

.partners {
  display: flex;
  align-items: center;
  gap: 15px;
}

.partner {
  overflow: hidden;

  img {
    width: 100%;
    height: 14px;
    object-fit: contain;
  }
}

.copyright {
  font-size: 1.6rem;
  color: rgba($base-0, 0.6);

  @include respond-to(xs) {
    display: none;
  }
}

._top {
  display: none;

  @include respond-to(xs) {
    display: block;
    font-size: 12px;
    margin-bottom: 40px;
  }
}
</style>
