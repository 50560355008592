<template>
  <div>
    <VButton size="small" color="transparent" @click="switchLanguage">
      <span :class="$style.text">{{ availableLocale }}</span>
    </VButton>
  </div>
</template>

<script setup lang="ts">
const { locale, locales } = useI18n();

const switchLocalePath = useSwitchLocalePath();

const availableLocale = computed(() => {
  const localeIndex = locales.value.findIndex(
    (loc: any) => loc.code === locale.value,
  );
  return locales.value[localeIndex === 0 ? 1 : 0].code;
});

const { getSettings } = useSettings();

const switchLanguage = async () => {
  await useRouter().replace(switchLocalePath(availableLocale.value));
  await getSettings();
};
</script>

<style lang="scss" module>
.text {
  font-size: 1.6rem;
  text-transform: uppercase;
}
</style>
