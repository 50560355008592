<template>
  <div :class="$style.HeaderUserMenu">
    <HeaderUserMenuEmail />
    <HeaderUserMenuNav />
    <HeaderUserMenuFooter />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" module>
.HeaderUserMenu {
  width: 30rem;
  border-radius: $border-radius-s;
  background-color: var(--main-block-bg);
  z-index: 3;
  box-shadow: 0 4px 50px 4px rgba(12 19 29 / 20%);
}
</style>
