<template>
  <input v-model="value" :class="$style.ThemeSwitcher" type="checkbox" />
</template>

<script setup lang="ts">
const colorMode = useColorMode();
const value = ref(colorMode.preference === "light");

watch(value, (newValue) => {
  if (newValue) colorMode.preference = "light";
  else colorMode.preference = "dark";
});
</script>

<style lang="scss" module>
.ThemeSwitcher {
  position: relative;
  width: 5.2rem;
  height: 3.2rem;
  background-color: var(--main-block-bg);
  border-radius: 3rem;
  transition: background-color $base-transition;
  cursor: pointer;

  @include respond-to(xs) {
    background-color: var(--theme-switcher-mob);
  }

  &:before {
    content: url("/images/themeSwitcher/moon.svg");
    z-index: 2;
    position: absolute;
    left: 4px;
    top: 4px;
    display: flex;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    border-radius: 50%;
    background-color: var(--theme-icon);
    white-space: nowrap;
    transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;

    @include respond-to(xs) {
      background-color: var(--main-block-bg);
    }
  }

  &:checked {
    &:before {
      left: 24px;
      content: url("/images/themeSwitcher/sun.svg");
    }
  }
}
</style>
